<template>
  <div>
    <!-- <div>
      <OtherLoading />
    </div> -->
    <div>
      <HeaderPanel
        :title="titleName"
        :filter="filter"
        @sidebar="sidebarFilter()"
        :hasDropdown="false"
        :hasSearch="false"
        :hasExport="hasExport"
        @export="exportModal"
      />
      <div>
        <div class="my-2 f-16" v-if="hasStat">
          <div v-if="stat.totalCustomer || stat.totalCustomer == 0">
            Total Customer <span v-if="tabIndex == 1">(Active Customer)</span>:
            {{ stat.totalCustomer | numeral("0,0") }} ({{
              stat.percentCustomer
            }}%)
          </div>

          <div v-if="displayDateFilter">
            {{ displayDateFilter }}
          </div>
          <div v-else-if="tabIndex != 1" style="height: 24px"></div>
        </div>
        <p class="my-2 f-16" v-if="tabIndex == 1">
          {{ displayDateTimeFilter.startDate | moment($formatDate) }} -
          {{ displayDateTimeFilter.endDate | moment($formatDate) }}
        </p>
      </div>
      <div class="mt-3">
        <b-tabs v-model="tabIndex" @input="handleTabsChange" fill>
          <b-tab title="Summary">
            <Summary
              ref="summary"
              v-if="tabIndex == 0"
              @setStat="setStat"
              @export="exportModal"
            />
          </b-tab>
          <b-tab title="Customer Analysis">
            <DashboardPanel
              v-if="tabIndex == 1"
              :dashBoardData="dashBoardData"
              :dashBoardRateData="dashBoardRateData"
              :dashBoardLabel="dashBoardLabel"
              :dashBoardDesc="dashBoardDesc"
              @exportExcel="exportExcel"
              :filter="filter"
              :isLoading="isLoading"
              :isChartLoading="isChartLoading"
              :dashBoardNumberData="dashBoardNumberData"
            />
          </b-tab>
          <b-tab title="Revenue"
            ><Revenue ref="revenue" v-if="tabIndex == 2" @setStat="setStat" />
          </b-tab>
          <b-tab title="Retention">
            <Retention
              ref="retention"
              v-if="tabIndex == 3"
              @setStat="setStat"
            />
          </b-tab>
          <b-tab title="RFM">
            <RFMPanel
              :RFMData="RFMData"
              v-if="tabIndex == 4 && !isLoadingRfm"
              @export="exportRFM"
            />
            <OtherLoading v-else />
          </b-tab>
          <b-tab title="First Purchase">
            <FirstPurchase
              ref="firstPurchase"
              v-if="tabIndex == 5"
              @setStat="setStat"
            />
          </b-tab>
          <!-- <b-tab title="Prospect"><Prospect ref="prospect" /></b-tab> -->
          <b-tab title="Customer profile">
            <ConnectChannel
              ref="connectChannel"
              v-if="tabIndex == 6"
              @setStat="setStat"
            />
          </b-tab>
        </b-tabs>
      </div>
    </div>
    <SidebarFilter
      ref="sidebarFilter"
      :filter="filter"
      @searchAll="filterSearch()"
      :hideSearchBar="true"
      :hideClearFilter="true"
      :hideStatusFilter="false"
    >
      <template v-slot:filter-option>
        <div class="mt-3">
          <div>
            <div class="main-label">Date From</div>
            <div :class="['input-container']">
              <datetime
                format="dd/MM/yyyy"
                v-model="filter.date_from"
                ref="startDate"
                @input="handleMinDate"
                placeholder="Please Select Date"
              >
              </datetime>
              <div
                class="icon-primary text-right"
                @click="$refs.startDate.isOpen = true"
              >
                <font-awesome-icon
                  icon="calendar-alt"
                  class="pointer color-primary"
                  color="#B41BB4"
                />
              </div>
            </div>
          </div>
          <div class="mt-3 mb-3">
            <div class="main-label">Date To</div>
            <div :class="['input-container']">
              <datetime
                format="dd/MM/yyyy"
                v-model="filter.date_to"
                :min-datetime="filter.date_from"
                placeholder="Please Select Date"
                ref="endDate"
              >
              </datetime>
              <div
                class="icon-primary text-right"
                @click="$refs.endDate.isOpen = true"
              >
                <font-awesome-icon
                  icon="calendar-alt"
                  class="pointer color-primary"
                  color="#B41BB4"
                />
              </div>
            </div>
          </div>
        </div>
      </template>
    </SidebarFilter>
    <SidebarRFMFilter
      ref="sidebarRFMFilter"
      @filterSearch="filterRFMSearch"
      @clearFilter="clearFilter"
      v-if="tabIndex == 4"
    />
    <ModalInputEmail
      ref="ModalInputEmail"
      :form="filterExportFile"
      @closeModal="clearExportFilter"
      @changeEmail="(val) => (filterExportFile.email = val)"
      @submit="handleExportCustomer"
    />
  </div>
</template>

<script>
const moment = require("moment");
// import "moment-range";
import DashboardPanel from "@/components/dashboard/DashboardPanel";
import OtherLoading from "@/components/loading/OtherLoading";
import SidebarFilter from "@/components/SideBarFilter";
import HeaderPanel from "@/components/HeaderPanel";
import RFMPanel from "@/components/dashboard/RFM/RFMPanel";
import SidebarRFMFilter from "@/components/dashboard/RFM/SidebarFilter";
import Revenue from "@/components/dashboard/Revenue";
import Retention from "@/components/dashboard/Retention";
import FirstPurchase from "@/components/dashboard/FirstPurchase";
// import Prospect from "@/components/dashboard/Prospect";
import Summary from "@/components/dashboard/Summary";
import ConnectChannel from "@/components/dashboard/ConnectChannel";
import ModalInputEmail from "@/components/customer/ModalInputEmail";

export default {
  components: {
    DashboardPanel,
    RFMPanel,
    SidebarRFMFilter,
    OtherLoading,
    SidebarFilter,
    HeaderPanel,
    Revenue,
    Retention,
    FirstPurchase,
    Summary,
    ModalInputEmail,
    // Prospect,
    ConnectChannel,
  },
  data() {
    return {
      tabIndex: 0,
      items: [],
      filter: {
        year_from: moment().subtract(7, "day").year(),
        month_from: moment().subtract(7, "day").month() + 1,
        day_from: moment().subtract(7, "day").date(),
        year_to: moment().subtract(1, "day").year(),
        month_to: moment().subtract(1, "day").month() + 1,
        day_to: moment().subtract(1, "day").date(),
        date_from: moment().subtract(7, "day").format(),
        date_to: moment().subtract(1, "day").format(),
      },
      filterExportFile: {
        email: "",
      },
      filterNewRFM: {
        selectedAll: {
          channel: false,
          product: false,
          customerLifeCycle: false,
          recency: false,
          frequency: false,
          monetary: false,
        } /*  */,
        rate_scale: 5,
        member_tiers: this.$store.state.filter.tier,
        channel_ids: this.$store.state.filter.channel,
        branch_ids: this.$store.state.filter.branch,
        age_group_ids: this.$store.state.filter.age,
        genders: this.$store.state.filter.gender,
        products: this.$store.state.filter.product,
        recencys: [],
        frequency: [],
        monetary: [],
        repurchase: [],
        product: this.$store.state.filter.product,
        TransactionDateFrom: this.$store.state.filter.from,
        TransactionDateTo: this.$store.state.filter.to,
        TypeDateFilter: 2,
        LastType: this.$store.state.filter.dateType,
      },
      isLoading: false,
      isLoadingRfm: true,
      isBusy: false,
      dashBoardData: [],
      dashBoardRateData: {
        current_first_purchase: 0,
        current_active: 0,
        current_lapse: 0,
        current_inactive: 0,
        first_purchase_rate: {
          value_r: 0,
          value_g: 0,
          percent_rate: 0,
        },
        active_rate: {
          value_r: 0,
          value_g: 0,
          percent_rate: 0,
        },
        lapse_rate: {
          value_r: 0,
          value_g: 0,
          percent_rate: 0,
        },
        inactive_rate: {
          value_r: 0,
          value_g: 0,
          percent_rate: 0,
        },
      },
      dashBoardLabel: [],
      dashBoardDesc: [],
      RFMData: [],
      hasExport: true,
      stat: {
        totalCustomer: 0,
        percentCustomer: 0,
      },
      displayDateFilter: "",
      chartList: [],
      isChartLoading: false,
      dashBoardNumberData: {
        first_purchase: 0,
        first_purchase_to_lapse: 0,
        active_customer: 0,
        active_to_lapse: 0,
        lapse: 0,
        lapse_to_inactive: 0,
        inactive: 0,
        inactive_to_active: 0,
      },
    };
  },
  created() {
    if (this.$route.query.type) this.tabIndex = Number(this.$route.query.type);
  },
  watch: {
    tabIndex(val) {
      this.stat = { totalCustomer: 0, percentCustomer: 0 };
      this.displayDateFilter = "";

      if ([0, 2, 5, 6, 7].includes(val)) this.hasExport = true;
      else this.hasExport = false;

      if (val == 1) {
        this.getDashboardList();
        this.getRateDataDashboard();
      } else if (val == 4) {
        this.getRFMList();
      }
    },
  },
  computed: {
    hasStat() {
      if ([0, 1, 2, 3, 4, 5, 6].includes(this.tabIndex)) return true;
      else return false;
    },
    RetentionCustomer() {
      if (this.$refs.retention) {
        return this.$refs.retention.stat.totalCustomer.toLocaleString();
      }
      return 0;
    },
    RetentionCustomerPercent() {
      if (this.$refs.retention) {
        return this.$refs.retention.stat.percentCustomer;
      }
      return 0;
    },
    titleName() {
      if (this.tabIndex == 0) return "Summary";
      else if (this.tabIndex == 1) return "Customer Analysis Dashboard";
      else if (this.tabIndex == 2) return "Revenue";
      else if (this.tabIndex == 3) return "Retention";
      else if (this.tabIndex == 4) return "RFM";
      else if (this.tabIndex == 5) return "First Purchase";
      // else if (this.tabIndex == 6) return "Prospect";
      else if (this.tabIndex == 6) return "Customer profile";
      return "";
    },
    displayDateTimeFilter() {
      let date = {
        startDate: this.filter.start_date,
        endDate: this.filter.end_date,
      };
      date = {
        startDate: this.filter.date_from,
        endDate: this.filter.date_to,
      };
      return date;
    },
  },
  methods: {
    handleTabsChange(a) {
      this.$router.replace({
        query: { type: a },
      });
    },
    async exportExcel(id, name) {
      this.$bus.$emit("showLoading");

      try {
        var data = null;
        if (typeof id == "object") {
          let payload = {
            list_report_life_cycle_type_id: id,
            ...this.filter,
          };
          const res = await this.$report.post(
            `/DashBoard/customer_analysis/date/export`,
            payload,
            {
              responseType: "blob",
            }
          );
          data = res;
        } else {
          let payload = {
            rate_report_type: id,
            list_report_life_cycle_type_id: [],
            ...this.filter,
          };
          const res = await this.$report.post(
            `/DashBoard/customer_analysis/rate/export`,
            payload,
            {
              responseType: "blob",
            }
          );
          data = res;
        }

        // this.isLoading = true;

        var fileURL = window.URL.createObjectURL(new Blob([data.data]));
        var fileLink = document.createElement("a");
        // var dateExcel = this.$moment().format("DDMMYYYYhhmmss");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", name + `.xlsx`);
        document.body.appendChild(fileLink);
        fileLink.click();
        this.$bus.$emit("hideLoading");
        // this.isLoading = false;
      } catch (error) {
        this.$bus.$emit("hideLoading");
        this.errorAlert(error.message);
      }
    },
    async exportRFM(params) {
      // this.isLoading = true;
      this.$bus.$emit("showLoading");
      let payload = { ...this.filterNewRFM };
      payload.report_type = params;
      delete payload.selectedAll;

      await this.$report.post(`/dashboard/rfm_customer_report`, payload, {
        responseType: "blob",
      });
      this.$bus.$emit("hideLoading");
      this.confirmAlert({
        message: "Do you want to download the file now?",
        title: "Success !",
        icon: "success",
        confirmButtonText: "Go To Page",
        cancelButtonText: "Close",
      }).then((val) => {
        if (val.isConfirmed) {
          const routeData = this.$router.resolve({
            name: "Customer Report List",
          });
          window.open(routeData.href, "_blank");
        }
      });
      // this.isLoading = false;
    },
    getDashbardLabelFromFilter() {
      const startDate = moment(this.filter.date_from);
      const endDate = moment(this.filter.date_to).add(1, "day");

      const dateRange = [];
      let currentDate = startDate;

      while (currentDate.isBefore(endDate)) {
        dateRange.push(currentDate.clone().format("DD/MM/YYYY"));
        currentDate.add(1, "day");
      }

      this.dashBoardLabel = dateRange;
    },
    getDataFromDateLabel(data) {
      let result = this.dashBoardLabel.map((date) => {
        const value = data.find(
          (el) => date == moment(el.date_time).format("DD/MM/YYYY")
        );

        return value ? value.total : 0;
      });

      return result;
    },
    sidebarFilter() {
      if (this.tabIndex == 0) this.$refs.summary.$refs.filter.show();
      else if (this.tabIndex == 2)
        this.$refs.revenue.$refs.revenueFilter.show();
      else if (this.tabIndex == 3)
        this.$refs.retention.$refs.retentionFilter.show();
      else if (this.tabIndex == 4)
        this.$refs.sidebarRFMFilter.show(this.filterNewRFM);
      else if (this.tabIndex == 5)
        this.$refs.firstPurchase.$refs.firstPurchaseFilter.show();
      // else if (this.tabIndex == 6)
      // this.$refs.prospect.$refs.prospectFilter.show();
      else if (this.tabIndex == 6)
        this.$refs.connectChannel.$refs.filter.show();
      else this.$refs.sidebarFilter.show(true);
    },
    searchList() {
      if (this.tabIndex == 4) this.getRFMList();
      else this.getDashboardList();
    },
    async getDashboardList() {
      try {
        this.getDashbardLabelFromFilter();
        // this.isLoading = true;
        this.isChartLoading = true;
        const res = await this.$report.post(
          `/DashBoard/customer_analysis/date`,
          this.filter
        );
        var response = res.data.detail;
        // console.log(response);
        let temp = [];

        const returnColor = (key) => {
          let purple = [
            "first_purchase",
            "active_customer",
            "lapse",
            "inactive_customer",
          ];
          let red = [
            "first_purchase_to_lapse",
            "active_to_lapse",
            "lapse_to_inactive",
          ];
          if (purple.includes(key)) return "#952cc9";
          if (red.includes(key)) return "#fb2b38";
          return "#47bb57";
        };
        this.dashBoardNumberData = response.current_data;
        for (const items of Object.keys(response)) {
          if (items == "current_data") {
          } else {
            let value = response[items];

            temp.push({
              report_id:
                value.length > 0 ? value[0].report_life_cycle_type_id : 0,
              label:
                items.replaceAll("_", " ")[0].toUpperCase() +
                items.slice(1).replaceAll("_", " ").toLowerCase(),
              data: this.getDataFromDateLabel(value),
              borderColor: returnColor(items),
              backgroundColor: returnColor(items),
              fill: false,
              key: items,
              pointBackgroundColor: returnColor(items),
              pointBorderColor: "rgba(0, 0, 0, 0)",
              pointRadius: 0, // Set the radius as needed
              fill: false,
              tension: 0.1,
            });
          }
        }

        this.dashBoardDesc = response;
        this.dashBoardData = temp;
        this.isChartLoading = false;
        // this.isLoading = false;
      } catch (error) {
        console.log(error);
        this.isLoading = false;
      }
    },
    async getRateDataDashboard() {
      this.isLoading = true;
      const res = await this.$report.post(
        `/DashBoard/customer_analysis/rate`,
        this.filter
      );
      this.dashBoardRateData = res.data.detail;
      this.stat.totalCustomer = res.data.detail.total_customer;
      this.stat.percentCustomer = res.data.detail.percent_total_customer;
      this.isLoading = false;
    },

    filterSearch() {
      this.filter = {
        year_from: moment(this.filter.date_from).year(),
        month_from: moment(this.filter.date_from).month() + 1,
        day_from: moment(this.filter.date_from).date(),
        year_to: moment(this.filter.date_to).year(),
        month_to: moment(this.filter.date_to).month() + 1,
        day_to: moment(this.filter.date_to).date(),
        date_from: this.filter.date_from,
        date_to: this.filter.date_to,
      };

      this.getDashboardList();
    },

    // ------------RFM------------ //

    async getRFMList() {
      this.isLoadingRfm = true;

      this.filterNewRFM.products = this.filterNewRFM.product.map(
        (el) => el.value
      );
      const res = await this.$report.post(
        `/dashboard/rfm_dashboard_graph`,
        this.filterNewRFM
      );

      this.RFMData = res.data.detail.rfm_report;
      let date =
        this.filterNewRFM.LastType == 0
          ? `${this.$moment(this.filterNewRFM.TransactionDateFrom).format(
              "DD MMM YYYY"
            )} - ${this.$moment(this.filterNewRFM.TransactionDateTo).format(
              "DD MMM YYYY"
            )}`
          : this.$displayFilterDate(this.filterNewRFM.LastType);
      let stat = {
        totalCustomer: res.data.detail.total_customer,
        percentCustomer: res.data.detail.percent_customer,
      };
      this.setStat(stat, date);

      this.isLoadingRfm = false;
    },
    filterRFMSearch(value) {
      this.filterNewRFM = value;

      this.getRFMList();
    },
    clearFilter(filter) {
      this.filterNewRFM = filter;

      this.getRFMList();
    },
    exportModal() {
      this.handleExportCustomer();
    },
    handleMinDate() {
      let diff = this.$moment(this.filter.date_to).diff(
        this.$moment(this.filter.date_from),
        "min"
      );
      if (diff < 0) this.filter.date_to = "";
    },
    setStat(val, date) {
      this.displayDateFilter = date;

      this.stat.totalCustomer = val ? val.totalCustomer : 0;
      this.stat.percentCustomer = val ? val.percentCustomer : 0;
    },
    async handleExportCustomer() {
      var res = null,
        fileName = "";
      this.$bus.$emit("showLoading");
      switch (this.tabIndex) {
        case 0:
          // payload.email = this.filterExportFile.email;
          res = await this.$report.post(`/DashBoard/summary/report/customer`, {
            ...this.$refs.summary.filter,
          });
          fileName = "Summary customer" + new Date();
          break;
        case 2:
          let payload = { ...this.$refs.revenue.filter };
          // payload.email = this.filterExportFile.email;
          res = await this.$report.post(
            `/RevenueReport/revenue_customer_file`,
            payload
          );
          fileName = "Revenue" + new Date();
          break;
        case 4:
          res = await this.$report.post(
            `/DashBoard/rfm_customer_report`,
            this.filterNewRFM,
            {
              responseType: "blob",
            }
          );
          fileName = "RFM" + new Date();
          break;
        case 5:
          // payload.email = this.filterExportFile.email;
          res = await this.$report.post(
            `/Dashboard/first_purchase/export_report/customer`,
            { ...this.$refs.firstPurchase.filter }
          );
          fileName = "First Purchase" + new Date();
          break;
        // case 6:
        //   this.$refs.prospect.exportFile();
        case 6:
          // payload.email = this.filterExportFile.email;
          res = await this.$report.post(
            `/dashboard/customer_connect/report_customer`,
            { ...this.$refs.connectChannel.filter }
          );
          fileName = "Customer profile" + new Date();
          break;

        default:
          break;
      }
      if (
        this.tabIndex == 0 ||
        this.tabIndex == 2 ||
        this.tabIndex == 5 ||
        this.tabIndex == 6
      ) {
        if (res.data.result)
          this.confirmAlert({
            message: "Do you want to download the file now?",
            title: "Success !",
            icon: "success",
            confirmButtonText: "Go To Page",
            cancelButtonText: "Close",
          }).then((val) => {
            if (val.isConfirmed) {
              // this.deleteItem(id);
              const routeData = this.$router.resolve({
                name: "Customer Report List",
              });
              window.open(routeData.href, "_blank");
            }
          });
        else this.errorAlert(res.data.message);
      } else {
        let data = res;

        var fileURL = window.URL.createObjectURL(new Blob([data.data]));
        var fileLink = document.createElement("a");
        // var dateExcel = this.$moment().format("DDMMYYYYhhmmss");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", fileName + `.xlsx`);
        document.body.appendChild(fileLink);
        fileLink.click();
      }
      this.$bus.$emit("hideLoading");
    },
    clearExportFilter() {
      this.filterExportFile.email = "";
    },
  },
};
</script>

<style lang="scss" scoped>
.alert-error {
  text-align: center;
  padding: 20px;
  color: #000;
}
// ::v-deep .nav.nav-tabs ::-webkit-scrollbar {
//   height: 2px;
// }
::v-deep .nav.nav-tabs {
  background-color: white;
  border: none;
  overflow-x: auto;
  flex-wrap: inherit;
  min-height: 40px;
  overflow-y: hidden;

  .nav-link.active,
  .nav-item.show .nav-link {
    background-color: transparent !important;
    color: var(--primary-color) !important;
    border-bottom: 4px solid var(--primary-color) !important;
  }
  .nav-link {
    border: none;
    border-color: #d8dbe0 !important;
    border-right: 1px solid #cfcfcf;
    // min-width: 150px;
    padding: 8px 30px;
    text-align: center;
    color: #333 !important;
  }
  :last-child .nav-link {
    border-right: 0 !important;
  }
}
::v-deep .btn-filter button,
.btn-filter {
  color: var(--primary-color) !important;
  border-color: var(--primary-color);
  font-weight: 100;
}
::v-deep .btn-export {
  background-color: transparent !important;
  color: var(--primary-color) !important;
  text-decoration: underline;
  border: 0 !important;
  padding-right: 0 !important;
}

::v-deep .chart-title {
  color: var(--font-color);
  margin-bottom: 0.5rem !important;
  display: flex;
  justify-content: space-between;
  align-content: center;
  font-weight: 700 !important;
  .title {
    font-size: 1rem;
  }
  .sub-titile {
    color: #717171 !important;
    font-weight: normal !important;
    font-size: 16px;
  }
}
</style>
